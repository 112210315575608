// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("/opt/build/repo/src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-data-protection-js": () => import("/opt/build/repo/src/pages/data-protection.js" /* webpackChunkName: "component---src-pages-data-protection-js" */),
  "component---src-pages-docs-js": () => import("/opt/build/repo/src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-imprint-js": () => import("/opt/build/repo/src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("/opt/build/repo/src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-process-js": () => import("/opt/build/repo/src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-team-js": () => import("/opt/build/repo/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-treatment-js": () => import("/opt/build/repo/src/pages/treatment.js" /* webpackChunkName: "component---src-pages-treatment-js" */)
}

